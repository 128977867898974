import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  Pagination,
  ProgressBar,
  Select,
  SpaceBetween,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { DelayOptions, DeviceMutationStatuses, OutputCommands } from 'src/constants/Constants';
import React, { useEffect, useState } from 'react';
import { debug, getKeywordTranslationKey, validEmailAddresses } from '../utils/commonUtils';
import { selectUsername, selectUserSelectedSite } from '../stores/slices/userSlice';
import EmptyState from './EmptyState';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { SingleSelectionEventType } from './DevicesTable';
import TranslateText from './TranslateText';
import { setOutputCommand } from 'src/utils/DevicesUtils';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useBundle } from '@amzn/react-arb-tools';
import { useSelector } from 'react-redux';

interface ChangeOutputInterface {
  changeOutputVisible: boolean;
  emailAddresses: string;
  selectedDevices?: SilencioDevice[];
  setChangeOutputVisibleCallback(visible: boolean): void;
  singleSelection?: { device: SilencioDevice, selection: SingleSelectionEventType } | null;
  totalDevicesCount?: number;
  updateDeviceCallback(updatedDevice: SilencioDevice): void;
}

export default function ChangeOutput(props: ChangeOutputInterface) {
  debug(`ChangeOutput() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.ChangeOutput');

  const [confirmed, setConfirmed] = useState(false);
  const [commandOption, setCommandOption] = useState<any>(props.singleSelection?.selection);
  const [delay, setDelay] = useState<any>({ label: 'None', value: '0' });
  const [disableSelectCommand, setDisableSelectCommand] = useState<boolean>(false);
  const [emailAddresses, setEmailAddresses] = useState(props.emailAddresses);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [progressDescription, setProgressDescription] = useState('');
  const [selectedDevices, setSelectedDevices] = useState<any[]>(
    props.singleSelection ? [{ ...props.singleSelection.device, setOutputCommandStatus: DeviceMutationStatuses.notStarted }] :
      props.selectedDevices!.map((sd: SilencioDevice) => {
        return {
          ...sd,
          setOutputCommandStatus: DeviceMutationStatuses.notStarted
        }
      }));
  const [showProgress, setShowProgress] = useState(false);

  const username = useSelector(selectUsername);
  const userSelectedSite = useSelector(selectUserSelectedSite);

  const setOutputCommandCallbackSuccess = (result: any) => {
    console.log('ChangeOutput() setOutputCommandCallbackSuccess() result is ', result);
    console.log('ChangeOutput() setOutputCommandCallbackSuccess() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    console.log('ChangeStatus() setOutputCommandCallbackSuccess() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
    const updatedSelectedDevice: any = selectedDevicesCopy[updatedSelectedDeviceIndex];
    if (result.status === 200 && result.devices[0].status === 'Success') {
      updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.updated;
      if (commandOption.value == "Activate") {
        updatedSelectedDevice.OutputStatus = "Activated";
      } else if (commandOption.value == "Deactivate") {
        updatedSelectedDevice.OutputStatus = "Deactivated";
      }
      const updatedSelectedDeviceForCallback = { ...updatedSelectedDevice };
      delete updatedSelectedDeviceForCallback.setOutputCommandStatus;
      console.log('ChangeOutput() setOutputCommandCallbackSuccess() calling props.updateDeviceCallback() with ', updatedSelectedDeviceForCallback);
      props.updateDeviceCallback(updatedSelectedDeviceForCallback);
    } else {
      updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.failure;
    }
    console.log('ChangeOutput() setOutputCommandCallbackSuccess() updatedSelectedDevice is ', updatedSelectedDevice);
    selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
    console.log('ChangeStatus() setDeviceMaskCallbackSuccess() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setOutputCommandCallbackFailure = (result: any) => {
    console.log('ChangeOutput() setOutputCommandCallbackFailure() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result.devices[0].deviceName);
    const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
    failedUpdateSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.failure;
    selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDevice);
    console.log('ChangeStatus() setDeviceMaskCallbackFailure() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
  };

  const setOutputCommandCallbackSuccessForDelay = (result: any) => {
    console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() result is ', result);
    console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() selectedDevices is ', selectedDevices);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() result_device is ', result_device);
      const updatedSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() updatedSelectedDeviceIndex is ', updatedSelectedDeviceIndex);
      const updatedSelectedDevice = selectedDevicesCopy[updatedSelectedDeviceIndex];
      if (result.status === 202) updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.submitted;
      if (result.status !== 202) updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.failure;
      console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() updatedSelectedDevice is ', updatedSelectedDevice);
      selectedDevicesCopy.splice(updatedSelectedDeviceIndex, 1, updatedSelectedDevice);
      console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    }
    setSelectedDevices(selectedDevicesCopy);
    console.log('ChangeStatus() setOutputCommandCallbackSuccessForDelay() done with call to setSelectedDevices using ', selectedDevicesCopy);
    setProgressDescription(`Complete: Submitted Request ${result.requestUUID}`);
    setProgress(100);
    setInProgress(false);
  };

  const setOutputCommandCallbackFailureForDelay = (result: any) => {
    console.log('ChangeStatus() setOutputCommandCallbackFailureForDelay() result is ', result);
    const selectedDevicesCopy = [...selectedDevices];
    for (let result_device of result.devices) {
      const failedUpdateSelectedDeviceIndex = selectedDevicesCopy.findIndex((sd: any) => sd.DeviceName === result_device.deviceName);
      const failedUpdateSelectedDevice = selectedDevicesCopy[failedUpdateSelectedDeviceIndex];
      failedUpdateSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.failure;
      selectedDevicesCopy.splice(failedUpdateSelectedDeviceIndex, 1, failedUpdateSelectedDevice);
    }
    console.log('ChangeStatus() setOutputCommandCallbackFailureForDelay() selectedDevicesCopy is ', selectedDevicesCopy);
    setSelectedDevices(selectedDevicesCopy);
    setProgressDescription(`Failure: Submitted Request ${result.requestUUID}`);
    setProgress(100);
    setInProgress(false);
  };

  const processDevices = () => {
    if (inProgress) return;
    console.log('ChangeOutput() processDevices()');
    let updatedSelectedDevices: SilencioDevice[] = [];
    const selectedDevicesCopy = [...selectedDevices];
    console.log('ChangeOutput() processDevices() selectedDevicesCopy.length is ', selectedDevicesCopy.length);
    console.log('ChangeOutput() processDevices() selectedDevicesCopy is ', selectedDevicesCopy);
    console.log('ChangeOutput() processDevices() delay.value is ', delay.value);
    if (delay.value > 0) {
      for (let i = 0; i < selectedDevicesCopy.length; i++) {
        const updatedSelectedDevice = selectedDevicesCopy[i];
        updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevicesCopy.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
      const devices = selectedDevicesCopy.map((sd: any) => { return { deviceName: sd.DeviceName, deviceHref: sd.device_href, deviceType: sd.Device_Type } });
      console.log('ChangeStatus() processDevices() devices is ', devices);
      setOutputCommand(
        commandOption.value,
        selectedDevices[0].devicesource!,
        devices,
        emailAddresses,
        delay.value * 60,
        username,
        userSelectedSite.sitename,
        userSelectedSite.siteRegion)
        .then(setOutputCommandCallbackSuccessForDelay, setOutputCommandCallbackFailureForDelay);
    } else {
      for (let i = 0; i < selectedDevices.length; i++) {
        const sd = selectedDevices[i]
        if (!sd.DeviceName || !sd.devicesource) continue;
        const devices = [{ deviceName: sd.DeviceName, deviceHref: sd.device_href, deviceType: sd.Device_Type }];
        setOutputCommand(
          commandOption.value,
          sd.devicesource!,
          devices,
          '',
          0,
          username,
          userSelectedSite.sitename,
          userSelectedSite.siteRegion)
          .then(setOutputCommandCallbackSuccess, setOutputCommandCallbackFailure);
        const updatedSelectedDevice = sd;
        updatedSelectedDevice.setOutputCommandStatus = DeviceMutationStatuses.inProgress;
        updatedSelectedDevices = [...selectedDevices.filter((sd: SilencioDevice) => sd.DeviceName !== updatedSelectedDevice.DeviceName), updatedSelectedDevice];
      }
      console.log('ChangeOutput() processDevices() updatedSelectedDevices is ', updatedSelectedDevices);
      setSelectedDevices(updatedSelectedDevices);
    }
  };

  const confirmBtnHandler = () => {
    console.log('ChangeOutput() confirmBtnHandler()');
    setDisableSelectCommand(true);
    setProgress(0);
    setInProgress(true);
    setConfirmed(true);
    setShowProgress(true);
    processDevices();
  };

  useEffect(() => {
    if (progress >= 100 || delay.value > 0) return;
    debug(`ChangeOutput() useEffect() [showProgress, selectedDevices] progress is ${progress} showProgress is ${showProgress}`);
    debug(`ChangeOutput() useEffect() [showProgress, selectedDevices] selectedDevices is ${JSON.stringify(selectedDevices)}`);
    if (showProgress) {
      debug(`ChangeOutput() useEffect() [showProgress, selectedDevices] selectedDevices.length is ${selectedDevices.length}`);
      let processedCount = 0;
      for (let i = 0; i < selectedDevices.length; i++) {
        if (selectedDevices[i].setOutputCommandStatus === DeviceMutationStatuses.updated ||
          selectedDevices[i].setOutputCommandStatus === DeviceMutationStatuses.failure ||
          selectedDevices[i].setOutputCommandStatus === DeviceMutationStatuses.submitted)
          processedCount++;
      }
      debug(`ChangeOutput() useEffect() [showProgress, selectedDevices] processedCount is ${processedCount}`);
      setProgress(Math.ceil(processedCount / selectedDevices.length * 100));
      const remaining = selectedDevices.length - processedCount;
      if (remaining === 0)
        setProgressDescription('Complete');
      else
        setProgressDescription(`${remaining} Devices Remaining...`);
    }
  }, [showProgress, selectedDevices]);

  useEffect(() => {
    if (progress < 100 || delay.value > 0) return;
    debug(`ChangeOutput() useEffect() [progress] progress is ${progress}`);
    const selectedDevicesCopy = [...selectedDevices];
    selectedDevicesCopy.sort((a, b) => {
      if (b.setOutputCommandStatus !== DeviceMutationStatuses.failure && a.setOutputCommandStatus === DeviceMutationStatuses.failure) return 0;
      return 1;
    });
    debug(`ChangeOutput() useEffect() [progress] selectedDevicesCopy is ${JSON.stringify(selectedDevicesCopy)}`);
    setSelectedDevices(selectedDevicesCopy);
  }, [progress]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    selectedDevices,
    {
      filtering: {
        empty: (
          <EmptyState
            title="No devices"
            subtitle="No devices to display."
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 5 },
      sorting: {},
      selection: {},
    }
  );

  const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
  };

  useEffect(() => {
    // automatically confirm for single device selection
    debug(`ChangeOutput() useEffect() [] props.singleSelection is ${JSON.stringify(props.singleSelection)} command is ${JSON.stringify(commandOption)} items.length is ${items.length}`);
    if (props.singleSelection && commandOption && items.length > 0) confirmBtnHandler();
  }, [])

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      footer={
        <Box float={'right'}>
          <Button
            disabled={!commandOption?.value || showProgress && progress < 100 || (delay.value > 0 && !validEmailAddresses(emailAddresses))}
            onClick={() => {
              if (progress === 0) confirmBtnHandler();
              if (progress >= 100) props.setChangeOutputVisibleCallback(false);
            }}
            variant="primary"
          >
            {progress < 100 ? bundle.getMessage('confirm') : bundle.getMessage('ok')}
          </Button>
        </Box>
      }
      header={bundle.formatMessage('change-command', { selectedCount: selectedDevices.length, total: props.totalDevicesCount })}
      onDismiss={() => props.setChangeOutputVisibleCallback(false)}
      size="large"
      visible={props.changeOutputVisible}
    >
      <Table
        {...collectionProps}
        trackBy='DeviceName'
        header={
          <>
            {
              showProgress &&
              <ProgressBar
                status="in-progress"
                value={progress}
                additionalInfo=""
                description={progressDescription}
                label={bundle.formatMessage('update-command', { newCommand: commandOption.label })}
              />
            }
          </>
        }
        ariaLabels={{
          selectionGroupLabel: bundle.getMessage('devices-selection'),
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item: any) => {
            const isItemSelected = selectedItems.filter(
              (i: any) => i.name === item.name
            ).length;
            return `${item.name} is ${isItemSelected ? "" : "not"
              } selected`;
          }
        }}
        columnDefinitions={[
          {
            id: "deviceName",
            header: bundle.getMessage('device-name'),
            cell: (e: any) => e.DeviceName,
            sortingField: "DeviceName"
          },
          {
            id: "status",
            header: bundle.getMessage('status'),
            cell: (e: any) => <TranslateText translateKey={getKeywordTranslationKey(e.OutputStatus || 'Unknown')} />,
            sortingField: "OutputStatus"
          },
          {
            id: "setOutputCommandStatus",
            header: bundle.getMessage('status'),
            cell: (e: any) => {
              switch (e.setOutputCommandStatus) {
                case DeviceMutationStatuses.updated:
                  return <span style={{ color: 'green' }}><TranslateText translateKey={e.setOutputCommandStatus} /></span>
                case DeviceMutationStatuses.failure:
                  return <span style={{ color: 'red' }}><TranslateText translateKey={e.setOutputCommandStatus} /></span>
                case DeviceMutationStatuses.submitted:
                  return <span style={{ color: 'green' }}><TranslateText translateKey={e.setOutputCommandStatus} /></span>
                default:
                  return <TranslateText translateKey={e.setOutputCommandStatus} />
              }
            },
            sortingField: "setOutputCommandStatus"
          },
        ]}
        items={items}
        visibleColumns={[
          'deviceName',
          'status',
          'setOutputCommandStatus'
        ]}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={paginationLabels}
          />
        }
      />
    </Modal>
  )
}