import {
  Box,
  Button,
  FormField,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { DelimiterRegex, ILanguage, Languages } from '../../constants/Constants';
import React, { useState } from 'react';
import {
  selectUserPrefs,
  setUserPrefs
} from '../../stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { debug, validEmailAddresses } from '../../utils/commonUtils';
import { getMergedEmailAddresses, updateUserPrefs } from 'src/utils/UserPrefsUtils';
import { useBundle } from "@amzn/react-arb-tools";

interface IUserPreferencesProps {
  closeUserPreferencesCallback: Function;
  setDarkMode: Function;
}

export const UserPreferences = (props: IUserPreferencesProps) => {
  debug(`UserPreferences() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.navigation.UserPreferences');
  const dispatch = useDispatch();

  const userPrefs = useSelector(selectUserPrefs);

  const getUserPrefsDarkMode = (): boolean => {
    return JSON.parse(userPrefs.site)['darkMode'] || false;
  }

  const getUserPrefsLanguage = (): ILanguage | null => {
    if (userPrefs && userPrefs.site) {
      try {
        const languageStr = JSON.parse(userPrefs.site)['language'];
        if (languageStr) {
          const language: ILanguage = JSON.parse(languageStr);
          return language;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  }

  const [emailAddresses, setEmailAddresses] = useState<string>(getMergedEmailAddresses(userPrefs));
  const [selectedDarkMode, setSelectedDarkMode] = useState<boolean>(getUserPrefsDarkMode());
  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage | null>(getUserPrefsLanguage());

  const cancelHandler = () => {
    debug('UserPreferences() cancelHandler() Resetting unsaved changes back to user preferences');

    const darkMode = getUserPrefsDarkMode();

    if (darkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }

    props.setDarkMode(darkMode);

    props.closeUserPreferencesCallback()
  }

  const darkModeOnChangeHandler = (detail: ToggleProps.ChangeDetail) => {
    if (detail.checked) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
    setSelectedDarkMode(detail.checked);
  };

  const languageOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    if (detail.selectedOption.label && detail.selectedOption.value) {
      setSelectedLanguage({ id: detail.selectedOption.value, text: detail.selectedOption.label });
    }
  }

  const saveUserPrefs = async () => {
    const sitePrefs = JSON.parse(userPrefs?.site);
    debug(`UserPreferences() saveUserPrefs() sitePrefs is ${JSON.stringify(sitePrefs)}`);
    if (!sitePrefs) return;
    sitePrefs['darkMode'] = selectedDarkMode;
    sitePrefs['emailAddresses'] = emailAddresses.replace(DelimiterRegex, ',');
    if (selectedLanguage) {
      sitePrefs['language'] = JSON.stringify(selectedLanguage);
    }
    const newUserPrefs = {
      ...userPrefs,
      site: JSON.stringify(sitePrefs)
    };
    await updateUserPrefs(newUserPrefs);
    dispatch(setUserPrefs(newUserPrefs));
    props.closeUserPreferencesCallback();
  };

  if (isBundleLoading) return <Spinner />;

  return (
    <Modal
      visible={true}
      header={bundle.getMessage('user-preferences')}
      onDismiss={cancelHandler}
      closeAriaLabel={bundle.getMessage('close')}
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              disabled={emailAddresses !== '' && !validEmailAddresses(emailAddresses)}
              onClick={saveUserPrefs}
              variant='primary'
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='m' direction='vertical'>
        <FormField label={bundle.getMessage('dark-mode')}>
          <Toggle
            onChange={({ detail }) => darkModeOnChangeHandler(detail)}
            ariaLabel={bundle.getMessage('dark-mode')}
            checked={selectedDarkMode}
          />
        </FormField>
        <FormField label={bundle.getMessage('language')}>
          <Select
            onChange={({ detail }) => languageOnChangeHandler(detail)}
            options={Languages.map(l => { return { label: l.text, value: l.id }; }).sort((c, p) => c.label! < p.label! ? -1 : 1)}
            selectedOption={selectedLanguage ? { label: selectedLanguage.text, value: selectedLanguage.id } : null}
          />
        </FormField>
        <FormField
          description={bundle.getMessage('email-addresses-description')}
          label={bundle.getMessage('email-addresses')}
        >
          <Textarea
            invalid={emailAddresses !== '' && !validEmailAddresses(emailAddresses)}
            onChange={({ detail }) => setEmailAddresses(detail.value)}
            placeholder={bundle.getMessage('email-addresses-description')}
            value={emailAddresses}
          />
        </FormField>
      </SpaceBetween>
    </Modal>
  );
}
