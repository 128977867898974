import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import TranslateText from './TranslateText';
import { getKeywordTranslationKey } from 'src/utils/commonUtils';

function maskedComparator(a: boolean | undefined | null, b: boolean | undefined | null): number {
  const getTextVal = (masked: boolean | undefined | null) => masked ? 'Masked' : (masked === null || masked === undefined) ? 'Unknown' : 'Unmasked';

  const aMaskedText = getTextVal(a);
  const bMaskedText = getTextVal(b);
  return (aMaskedText.localeCompare(bMaskedText))
};

export const ColumnDefinitions: TableProps.ColumnDefinition<SilencioDevice>[] = [
  {
    id: 'Child_DeviceID',
    header: <TranslateText translateKey={'child-device-id'} />,
    cell: (item: SilencioDevice) => item.Child_DeviceID
  },
  {
    id: 'DeviceName',
    header: <TranslateText translateKey={'device-name'} />,
    cell: (e: SilencioDevice) => e.DeviceName,
    sortingField: 'DeviceName'
  },
  {
    id: 'IORelationOutputNames',
    header: <TranslateText translateKey={'connected-outputs'} />,
    cell: (e: SilencioDevice) => e.IORelationOutputNames?.join(', ') || '',
    sortingField: 'IORelationOutputNames'
  },
  {
    id: 'PairedReader',
    header: <TranslateText translateKey={'paired-reader'} />,
    cell: (e: SilencioDevice) => e.PairedReader,
    sortingField: 'PairedReader'
  },
  {
    id: 'Masked',
    header: <TranslateText translateKey={'status'} />,
    cell: (e: SilencioDevice) => {
      const translateKey = e.Masked ? 'masked' : (e.Masked === null || e.Masked === undefined) ? 'unknown' : 'unmasked';
      return <TranslateText translateKey={translateKey} />;
    },
    sortingField: 'Masked',
    sortingComparator: (a: SilencioDevice, b: SilencioDevice) => maskedComparator(a.Masked, b.Masked)
  },
  {
    id: 'DoorForcedOpen',
    header: <TranslateText translateKey={'door-forced-open'} />,
    cell: (e: SilencioDevice) => {
      const translateKey = e.DoorForcedMasked ? 'masked' : (e.DoorForcedMasked === null || e.DoorForcedMasked === undefined) ? 'unknown' : 'unmasked';
      return <TranslateText translateKey={translateKey} />;
    },
    sortingField: 'DoorForcedOpen',
    sortingComparator: (a: SilencioDevice, b: SilencioDevice) => maskedComparator(a.DoorForcedMasked, b.DoorForcedMasked)
  },
  {
    id: 'DoorHeldOpen',
    header: <TranslateText translateKey={'door-held-open'} />,
    cell: (e: SilencioDevice) => {
      const translateKey = e.DoorHeldMasked ? 'masked' : (e.DoorHeldMasked === null || e.DoorHeldMasked === undefined) ? 'unknown' : 'unmasked';
      return <TranslateText translateKey={translateKey} />;
    },
    sortingField: 'DoorHeldOpen',
    sortingComparator: (a: SilencioDevice, b: SilencioDevice) => maskedComparator(a.DoorHeldMasked, b.DoorHeldMasked)
  },
  {
    id: 'Mode',
    header: <TranslateText translateKey={'mode'} />,
    cell: (e: SilencioDevice) => <TranslateText translateKey={getKeywordTranslationKey(e.Mode || 'Unknown')} />,
    sortingField: 'Mode',
    width: 200
  },
  {
    id: 'OutputStatus',
    header: <TranslateText translateKey={'status'} />,
    cell: (e: SilencioDevice) => <TranslateText translateKey={getKeywordTranslationKey(e.OutputStatus || 'Unknown')} />,
    sortingField: 'OutputStatus'
  },
  {
    id: 'ReadOnly', // needed to highlight read only rows
    header: '',
    cell: (e: SilencioDevice) => <div data-testid='read-only-device' hidden>{e.read_only ? 'true' : 'false'}</div>,
    maxWidth: 0,
    minWidth: 0,
    width: 0,
  }
];

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({ title }: EmptyStateProps) {
  return (
    <Box textAlign='center' color='inherit'>
      <Box variant='strong' textAlign='center' color='inherit'>
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No matches</b>
        <Box variant='p' color='inherit'>
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
