import {
  Button,
  CollectionPreferences,
  Flashbar,
  FormField,
  Header,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  Textarea
} from '@amzn/awsui-components-react';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import { DelimiterRegex, DeviceSources, EmptyPropertyFilterQuery, Modes, SilencioDeviceFilteringProperties } from 'src/constants/Constants';
import React, { useState, useEffect, useRef } from 'react';
import {
  Search,
  selectInputDevices,
  selectInputsSearch,
  selectOutputDevices,
  selectOutputsSearch,
  selectReadersAlarmsDevices,
  selectReadersAlarmsSearch,
  selectReadersModesDevices,
  selectReadersModesSearch,
  selectSelectedInputs,
  selectSelectedOutputs,
  selectSelectedReadersAlarms,
  selectSelectedReadersModes,
  setSelectedInputs,
  setSelectedOutputs,
  setSelectedReadersAlarms,
  setSelectedReadersModes,
  updateInputMasked,
  updateOutputStatus,
  updateReaderMasked,
  updateReaderMode,
  updateSelectedInputMask,
  updateSelectedOutputStatus,
  updateSelectedReaderMasked,
  updateSelectedReaderMode
} from '../stores/slices/devicesSlice';
import {
  selectPhySecMember,
  selectSideTeam,
  selectSigAdmin,
  selectUserSelectedSitePrivs,
  selectUserPrefs,
  setUserPrefs
} from 'src/stores/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBar from './ButtonBar';
import ChangeMode from './ChangeMode';
import ChangeOutput from './ChangeOutput';
import ChangeStatus from './ChangeStatus';
import { debug, validEmailAddresses } from '../utils/commonUtils';
import InputsFilter from './InputsFilter';
import OutputsFilter from './OutputsFilter';
import { Privileges } from 'src/utils/UserSitesUtils';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import ReadersAlarmFilter from './ReadersAlarmFilter';
import ReadersModeFilter from './ReadersModeFilter';
import { SilencioDevice } from "src/utils/SilencioTypes";
import { getMergedEmailAddresses, updateUserPrefs } from 'src/utils/UserPrefsUtils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import '../styles/table-select.scss';

export type SingleSelectionEventType = {
  event: string,
  label: string,
  value: string,
}

export enum SingleSelectionEvents {
  inputMask = 'Input Mask',
  outputCommand = 'Output Command',
  readerDoorForcedMask = 'Door Forced Open',
  readerDoorHeldMask = 'Door Held Open',
  readerMode = 'Reader Mode'
};

interface DevicesTableInterface {
  darkMode: boolean;
  deviceType: string;
  loadingDevices: boolean;
  refreshDevicesCallback(): Promise<void>;
}

function DevicesTable(props: DevicesTableInterface) {
  debug(`DevicesTable(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.DevicesTable');
  const [commonBundle, isCommonBundleLoading] = useBundle('components.Common');
  const [prefsBundle, isPrefsBundleLoading] = useBundle('components.navigation.UserPreferences');

  const dispatch = useDispatch();

  const userSitePrivs = useSelector(selectUserSelectedSitePrivs) as Privileges[] | undefined;
  const userPrefs = useSelector(selectUserPrefs);
  const phySecMember = useSelector(selectPhySecMember);
  const sideTeam = useSelector(selectSideTeam);
  const sigAdmin = useSelector(selectSigAdmin);

  const getDevicesSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectInputDevices) as SilencioDevice[];
      case 'outputs':
        return useSelector(selectOutputDevices) as SilencioDevice[];
      case 'readersAlarms':
        return useSelector(selectReadersAlarmsDevices) as SilencioDevice[];
      case 'readersModes':
        return useSelector(selectReadersModesDevices) as SilencioDevice[];
    }
  };
  const devicesFromStore = getDevicesSelector();

  const getSelectedDevicesSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectSelectedInputs) as SilencioDevice[];
      case 'outputs':
        return useSelector(selectSelectedOutputs) as SilencioDevice[];
      case 'readersAlarms':
        return useSelector(selectSelectedReadersAlarms) as SilencioDevice[];
      case 'readersModes':
        return useSelector(selectSelectedReadersModes) as SilencioDevice[];
    }
  };
  const selectedDevicesFromStore = getSelectedDevicesSelector();

  const getSearchSelector = () => {
    switch (props.deviceType) {
      case 'inputs':
        return useSelector(selectInputsSearch) as Search;
      case 'outputs':
        return useSelector(selectOutputsSearch) as Search;
      case 'readersAlarms':
        return useSelector(selectReadersAlarmsSearch) as Search;
      case 'readersModes':
        return useSelector(selectReadersModesSearch) as Search;
    }
  };
  const searchFromStore = getSearchSelector();

  const setSelectedDevicesInStore = (selectedDevices: SilencioDevice[]) => {
    switch (props.deviceType) {
      case 'inputs':
        dispatch(setSelectedInputs(selectedDevices));
        break;
      case 'outputs':
        dispatch(setSelectedOutputs(selectedDevices));
        break;
      case 'readersAlarms':
        dispatch(setSelectedReadersAlarms(selectedDevices));
        break;
      case 'readersModes':
        dispatch(setSelectedReadersModes(selectedDevices));
        break;
    }
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedDevicesInStore(detail.selectedItems as SilencioDevice[]);
  };

  const setPreferences = async (details: any) => {
    debug(`setPreferences(): details is ${JSON.stringify(details)}`);
    setShowPreferencesNotSaved(false);
    if (details.custom.emailAddress && details.custom.emailAddress !== '' && !validEmailAddresses(details.custom.emailAddresses)) {
      setShowPreferencesNotSaved(true);
      return;
    }
    setPageSize(details.pageSize);
    let nodeName = '';
    let typeName = '';
    switch (props.deviceType) {
      case 'inputs':
        nodeName = 'inputs';
        typeName = 'InputsPrefs';
        break;
      case 'outputs':
        nodeName = 'outputs';
        typeName = 'OutputsPrefs';
        break;
      case 'readersAlarms':
        nodeName = 'readersAlarms';
        typeName = 'ReadersAlarmsPrefs';
        break;
      case 'readersModes':
        nodeName = 'readersModes';
        typeName = 'ReadersModesPrefs';
        break;
    }
    const nodePrefs = userPrefs[nodeName] || {};
    const sitePrefs = JSON.parse(userPrefs.site);
    sitePrefs['emailAddresses'] = details.custom.emailAddresses.replace(DelimiterRegex, ',');
    const newUserPrefs = await updateUserPrefs(
      {
        ...userPrefs,
        [nodeName]: {
          ...nodePrefs,
          __typename: typeName,
          numRecordsPerPage: details.pageSize,
        },
        site: JSON.stringify(sitePrefs)
      });
    if (newUserPrefs) dispatch(setUserPrefs(newUserPrefs));
  };

  const resetSelectedItems = () => {
    debug(`DevicesTable() resetSelectedItems()`);
    setSelectedDevicesInStore([]);
  };

  const [singleSelection, setSingleSelection] = useState<{ device: SilencioDevice, selection: SingleSelectionEventType } | null>(null);
  const [changeStatusVisible, setChangeStatusVisible] = useState(false);
  const [changeModeVisible, setChangeModeVisible] = useState(false);
  const [changeOutputVisible, setChangeOutputVisible] = useState(false);
  const [showPreferencesNotSaved, setShowPreferencesNotSaved] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>(null);

  const pageSizeFromStore = (deviceType: string): number => {
    debug(`pageSizeFromStore() deviceType is ${deviceType}`);
    const defaultNumRecs = 50;
    let numRecs;
    try {
      switch (deviceType) {
        case 'inputs':
          numRecs = userPrefs.inputs.numRecordsPerPage;
          break;
        case 'outputs':
          numRecs = userPrefs.outputs.numRecordsPerPage;
          break;
        case 'readersAlarms':
          numRecs = userPrefs.readersAlarms.numRecordsPerPage;
          break;
        case 'readersModes':
          numRecs = userPrefs.readersModes.numRecordsPerPage;
          break;
      }
      debug(`pageSizeFromStore() numRecs is ${numRecs ?? defaultNumRecs}`);
      return numRecs ?? defaultNumRecs;
    } catch (error) {
      return defaultNumRecs;
    }
  }

  const [emailAddresses, setEmailAddresses] = useState(getMergedEmailAddresses(userPrefs));
  const [pageSize, setPageSize] = useState<number>(pageSizeFromStore(props.deviceType));

  /* true: Select All, false: Deselect All */
  const [selectDeselectAllButton, setSelectDeselectAllButton] = useState<boolean>(true);
  const [selectDeselectAllDisabled, setSelectDeselectAllDisabled] = useState<boolean>(false);

  const selectDeselectAll = () => {
    debug(`DevicesTable() selectDeselectAll() selectedDevicesFromStore.length is ${selectedDevicesFromStore?.length} devicesFromStore.length is ${devicesFromStore?.length} propertyFilterProps.query is ${propertyFilterProps.query}`);
    const filteredDevicesWOReadOnly = devicesFromStore?.filter(d => !d.read_only ).filter(d => {
      return filterItems(d, propertyFilterProps.query);
    });
    debug(`DevicesTable() selectDeselectAll() filteredDevices.length is ${filteredDevicesWOReadOnly?.length}`);
    if (!selectDeselectAllButton) {
      setSelectedDevicesInStore([]);
      debug(`DevicesTable() selectDeselectAll() setting label to "Select All"`);
      setSelectDeselectAllButton(true);
    } else if (selectDeselectAllButton && filteredDevicesWOReadOnly) {
      setSelectedDevicesInStore(filteredDevicesWOReadOnly);
      debug(`DevicesTable() selectDeselectAll() setting label to "Deselect All"`);
      setSelectDeselectAllButton(false);
    }
  };

  const setInputMaskButtonHandler = (device: SilencioDevice, selection: string) => {
    debug(`setInputMaskButtonHandler() device is ${JSON.stringify(device)} selection is ${selection}`);
    setSingleSelection({ device, selection: { event: SingleSelectionEvents.inputMask, label: selection, value: selection } });
    setChangeStatusVisible(true);
  };

  const setOutputCommandHandler = (device: SilencioDevice, selection: string) => {
    debug(`setOutputCommandHandler() device is ${JSON.stringify(device)} selection is ${selection}`);
    setSingleSelection({ device, selection: { event: SingleSelectionEvents.outputCommand, label: selection, value: selection } });
    setChangeOutputVisible(true);
  }

  const setReaderDoorForcedMaskButtonHandler = (device: SilencioDevice, selection: string) => {
    debug(`setReaderDoorForcedMaskButtonHandler() device is ${JSON.stringify(device)} selection is ${selection}`);
    setSingleSelection({ device, selection: { event: SingleSelectionEvents.readerDoorForcedMask, label: selection, value: selection } });
    setChangeStatusVisible(true);
  };

  const setReaderDoorHeldMaskButtonHandler = (device: SilencioDevice, selection: string) => {
    debug(`setReaderDoorHeldMaskButtonHandler() device is ${JSON.stringify(device)} selection is ${selection}`);
    setSingleSelection({ device, selection: { event: SingleSelectionEvents.readerDoorHeldMask, label: selection, value: selection } });
    setChangeStatusVisible(true);
  };

  const setReaderModeButtonHandler = (device: SilencioDevice, selection: string) => {
    debug(`setReaderModeButtonHandler() device is ${JSON.stringify(device)} selection is ${selection}`);
    setSingleSelection({ device, selection: { event: SingleSelectionEvents.readerMode, label: selection, value: selection } });
    setChangeModeVisible(true);
  };


  const filterMatches = (item: SilencioDevice) => {
    debug(`DevicesTable() filterMatches() props.deviceType is ${props.deviceType}`);
    debug(`DevicesTable() filterMatches() devicesFromStore.length is ${devicesFromStore?.length}`);
    if (!props.deviceType) return;
    switch (props.deviceType) {
      case 'inputs':
        if (searchFromStore?.maskStatus && searchFromStore.maskStatus.value && searchFromStore.maskStatus.value !== 'Any Status') {
          debug(`DevicesTable() filterMatches() searchFromStore.maskStatus is ${JSON.stringify(searchFromStore.maskStatus)}`);
          debug(`DevicesTable() filterMatches() item.deviceName is ${item.DeviceName} latestDevices.filter() item.Masked is ${item.Masked}`);
          switch (searchFromStore.maskStatus!.value) {
            case 'Unknown':
              return (item.Masked == undefined || item.Masked == null);
            case 'Masked':
              return (item.Masked);
            case 'Unmasked':
              return (item.Masked !== null && item.Masked !== undefined && !item.Masked);
            default:
              return true;
          };
        }
        return true;
      case 'outputs':
        if (searchFromStore?.outputStatus && searchFromStore.outputStatus.value && searchFromStore.outputStatus.value !== 'Any Status') {
          debug(`DevicesTable() filterMatches() searchFromStore.outputStatus is ${JSON.stringify(searchFromStore.outputStatus)}`);
          debug(`DevicesTable() filterMatches() item.deviceName is ${item.DeviceName} latestDevices.filter() item.OutputStatus is ${item.OutputStatus}`);
          if (searchFromStore.outputStatus.value == 'Unknown') {
            return (item.OutputStatus == undefined || item.OutputStatus == null || item.OutputStatus == 'Unknown');
          } else {
            return (item.OutputStatus == searchFromStore.outputStatus.value);
          }
        }
        return true;
      case 'readersAlarms':
        if (searchFromStore?.maskStatus && searchFromStore.maskStatus.value && searchFromStore.maskStatus.value !== 'Any Status') {
          debug(`DevicesTable() filterMatches() searchFromStore.maskStatus is ${JSON.stringify(searchFromStore.maskStatus)}`);
          debug(`DevicesTable() filterMatches() item.deviceName is ${item.DeviceName} item.DoorForcedMasked is ${JSON.stringify(item.DoorForcedMasked)}`);
          debug(`DevicesTable() filterMatches() item.deviceName is ${item.DeviceName} item.DoorHeldMasked is ${JSON.stringify(item.DoorHeldMasked)}`);
          switch (searchFromStore.maskStatus!.value) {
            case 'Unknown':
              return (((item.DoorForcedMasked == undefined || item.DoorForcedMasked == null) || (item.DoorHeldMasked == undefined || item.DoorHeldMasked == null)));
            case 'Masked':
              return (((item.DoorForcedMasked !== null && item.DoorForcedMasked) || (item.DoorHeldMasked !== null && item.DoorHeldMasked)));
            case 'Unmasked':
              return (
                ((item.DoorForcedMasked !== null && item.DoorForcedMasked !== undefined && !item.DoorForcedMasked)
                  || (item.DoorHeldMasked !== null && item.DoorHeldMasked !== undefined && !item.DoorHeldMasked)));
            default:
              return true;
          };
        }
        return true;
      case 'readersModes':
        if (searchFromStore && Array.isArray(searchFromStore.modes) && searchFromStore.modes.length < Modes.length) {
          debug(`DevicesTable() filterMatches() searchFromStore.modes is ${JSON.stringify(searchFromStore.modes)}`);
          let modesValues: { value: string }[] = (searchFromStore.modes.map(m => { return { value: m.value } }));
          let modes: string[] = [];
          for (let modesValue of modesValues) {
            debug(`DevicesTable() filterMatches() modesValue is ${JSON.stringify(modesValue)}`);
            modes.push(...Object.values(modesValue));
          }
          debug(`DevicesTable() filterMatches() modes is ${JSON.stringify(modes)}`);
          return modes.includes(item.Mode!);
        }
        return true;
      default:
        return true;
    }
  };

  const filterMaskingGroup = (item: SilencioDevice) => {
    if (searchFromStore?.maskingGroup) {
      const searchMG = searchFromStore.maskingGroup;
      debug(`DevicesTable() filterMaskingGroup() Filtering for masking group: ${searchMG.value} (${searchMG.label})`);
      return (item.MaskingGroups && item.MaskingGroups.find(mg => mg.id === searchMG.value));
    }
    return true;
  }

  const filterByOperator = (input: any, tokenValue: string, operator: string) => {
    try {
      switch (operator) {
        case '<':
          return input < tokenValue;
        case '<=':
          return input <= tokenValue;
        case '>':
          return input > tokenValue;
        case '>=':
          return input >= tokenValue;
        case '=':
          return input == tokenValue;
        case '!=':
          return input != tokenValue;
        case ':':
          return (typeof input === 'string' && input.toLowerCase().indexOf(tokenValue.toLowerCase()) > -1) || input.includes(tokenValue);
        case '!:':
          if (typeof input === 'string') {
            return input.toLowerCase().indexOf(tokenValue.toLowerCase()) == -1;
          }
          return !input.includes(tokenValue);
      }
    } catch (e) {
      return false;
    }
  };

  const filterItems = (item: SilencioDevice, query: PropertyFilterProps.Query) => {
    debug(`DevicesTable() filterItems() devicesFromStore.length is ${devicesFromStore?.length}`);
    debug(`DevicesTable() filterItems() query is ${JSON.stringify(query)}`);
    if (!filterMatches(item)) {
      return false;
    }
    if (!filterMaskingGroup(item)) {
      return false;
    }
    try {
      if (query.operation === "or") {
        for (const token of query.tokens) {
          const input = token.propertyKey ? (item as any)[token.propertyKey] : JSON.stringify(item);
          debug(`DevicesTable() filterItems() input ${input}`);
          debug(`DevicesTable() filterItems() tokenValue ${token.value}`);
          if (filterByOperator(input, token.value, token.operator)) {
            debug(`DevicesTable() filterItems() matched for "or" on key ${token.propertyKey || 'stringified item'}`);
            return true;
          }
        };
        return false;
      } else {
        for (const token of query.tokens) {
          const input = token.propertyKey ? (item as any)[token.propertyKey] : JSON.stringify(item);
          debug(`DevicesTable() filterItems() input ${input}`);
          debug(`DevicesTable() filterItems() tokenValue ${token.value}`);
          if (!filterByOperator(input, token.value, token.operator)) {
            return false;
          }
          debug(`DevicesTable() filterItems() matched for "and" on key ${token.propertyKey || 'stringified item'}`);
        };
        debug(`DevicesTable() filterItems() matched for all keys on "and"`);
        return true;
      }
    } catch (e) {
      debug(`DevicesTable() filterItems() Error: ${e}`);
      return false;
    }
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, propertyFilterProps } = useCollection(
    devicesFromStore ?? [],
    {
      propertyFiltering: {
        empty: <TableEmptyState title='No Devices' />,
        filteringFunction: filterItems,
        filteringProperties: SilencioDeviceFilteringProperties,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setPropertyFiltering(EmptyPropertyFilterQuery)} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'DeviceName' }
    }
  );

  const updateDevice = async (updatedDevice: SilencioDevice) => {
    debug(`DevicesTable() updatedDevice() updatedDevice is ${JSON.stringify(updatedDevice)} props.deviceType is ${props.deviceType}`);
    if (!updatedDevice) return;
    switch (props.deviceType) {
      case 'inputs':
        dispatch(updateInputMasked(updatedDevice));
        dispatch(updateSelectedInputMask(updatedDevice));
        break;
      case 'outputs':
        dispatch(updateOutputStatus(updatedDevice));
        dispatch(updateSelectedOutputStatus(updatedDevice));
        break;
      case 'readersAlarms':
        dispatch(updateReaderMasked(updatedDevice));
        dispatch(updateSelectedReaderMasked(updatedDevice));
        break;
      case 'readersModes':
        dispatch(updateReaderMode(updatedDevice));
        dispatch(updateSelectedReaderMode(updatedDevice));
        break;
    }
  };

  const onguardDevices = (): boolean => {
    debug(`DevicesTable() onguardDevices()`);
    const onguardDevicesFound = devicesFromStore?.find(d => d.devicesource === DeviceSources.ONGUARD) !== undefined;
    debug(`DevicesTable() onguardDevices() onguardDevicesFound is ${onguardDevicesFound}`);
    return onguardDevicesFound;
  };

  useEffect(() => {
    debug(`DevicesTable() useEffect() [selectedDevicesFromStore, propertyFilterProps.query] selectedDevicesFromStore.length is ${selectedDevicesFromStore?.length} devices.length is ${devicesFromStore?.length} propertyFilterProps.query is ${propertyFilterProps.query}`);
    const filteredDevicesWOReadOnly = devicesFromStore?.filter(d => !d.read_only).filter(d => {
      return filterItems(d, propertyFilterProps.query);
    });
    if (!filteredDevicesWOReadOnly) return;
    debug(`DevicesTable() useEffect() [selectedDevicesFromStore] filteredDevices.length is ${filteredDevicesWOReadOnly.length}`);
    if (selectedDevicesFromStore && selectedDevicesFromStore.length > 0 && selectedDevicesFromStore.length >= filteredDevicesWOReadOnly.length) {
      setSelectDeselectAllButton(false);
    }
    if (selectedDevicesFromStore && selectedDevicesFromStore.length < filteredDevicesWOReadOnly.length) {
      setSelectDeselectAllButton(true);
    }
  }, [selectedDevicesFromStore, propertyFilterProps.query]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [propertyFilterProps.query] selectedDevicesFromStore.length is ${selectedDevicesFromStore?.length} devices.length is ${devicesFromStore?.length} propertyFilterProps.query is ${propertyFilterProps.query}`);
    const filteredDevices = devicesFromStore?.filter(d => {
      return filterItems(d, propertyFilterProps.query);
    });
    if (!filteredDevices) return;
    debug(`DevicesTable() useEffect() [propertyFilterProps.query] filteredDevices.length is ${filteredDevices.length}`);
    if (selectedDevicesFromStore && selectedDevicesFromStore.length > 0 && selectedDevicesFromStore.length > filteredDevices.length) {
      setSelectedDevicesInStore(filteredDevices);
    }
    if (filteredDevices.length === 0 && !selectDeselectAllButton) setSelectDeselectAllButton(true);
  }, [propertyFilterProps.query]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [items.length] items.length is ${items.length}`);
    if (items.length === 0) setSelectDeselectAllDisabled(true);
    if (items.length > 0) setSelectDeselectAllDisabled(false);
  }, [items.length]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [changeModeVisible]`);
    if (!changeModeVisible && singleSelection) setSingleSelection(null);
  }, [changeModeVisible]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [changeStatusVisible]`);
    if (!changeStatusVisible && singleSelection) setSingleSelection(null);
  }, [changeStatusVisible]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [changeOutputVisible]`);
    if (!changeOutputVisible && singleSelection) setSingleSelection(null);
  }, [changeOutputVisible]);

  useEffect(() => {
    debug(`DevicesTable() useEffect() [userPrefs.site]`);
    setEmailAddresses(getMergedEmailAddresses(userPrefs));
  }, [userPrefs.site]);

  useEffect(() => {
    if (!tableRef.current) return;
    const tableRows = tableRef.current.querySelectorAll('tr');
    tableRows.forEach(row => {
      let disable = false;
      let visitorId = '';
      const rowColumns = row.querySelectorAll('td');
      rowColumns.forEach(column => {
        if (column.querySelector('div [data-testid="read-only-device"]')?.innerHTML === 'true') {
          debug(`DevicesTable() useEffect() [items, props.darkMode] setting disable to true`);
          disable = true;
          visitorId = column.querySelector('span')?.innerHTML || '';
        }
      });
      if (disable) {
        row.style.backgroundColor = 'lightgrey';
        row.style.color = 'black';
      }
    });
  }, [items, props.darkMode]);

  const getFilter = () => {
    switch (props.deviceType) {
      case 'inputs':
        return <InputsFilter
          filteredItemsCount={filteredItemsCount}
          refreshDevicesCallback={props.refreshDevicesCallback}
          resetSelectedItemsCallback={resetSelectedItems}
          setFilterCallback={(query: PropertyFilterProps.Query) => actions.setPropertyFiltering(query)}
        />
      case 'outputs':
        return <OutputsFilter
          filteredItemsCount={filteredItemsCount}
          resetSelectedItemsCallback={resetSelectedItems}
          setFilterCallback={(query: PropertyFilterProps.Query) => actions.setPropertyFiltering(query)}
        />
      case 'readersAlarms':
        return <ReadersAlarmFilter
          filteredItemsCount={filteredItemsCount}
          readersType={props.deviceType}
          refreshDevicesCallback={props.refreshDevicesCallback}
          resetSelectedItemsCallback={resetSelectedItems}
          setFilterCallback={(query: PropertyFilterProps.Query) => actions.setPropertyFiltering(query)}
        />
      case 'readersModes':
        return <ReadersModeFilter
          filteredItemsCount={filteredItemsCount}
          readersType={props.deviceType}
          refreshDevicesCallback={props.refreshDevicesCallback}
          resetSelectedItemsCallback={resetSelectedItems}
          setFilterCallback={(query: PropertyFilterProps.Query) => actions.setPropertyFiltering(query)}
        />
    }
  }

  if (isBundleLoading || isCommonBundleLoading || isPrefsBundleLoading) return <Spinner />;

  return (
    <div id='tableDiv' ref={tableRef}>
      {showPreferencesNotSaved
      &&
      <Flashbar
        items={[{
          dismissible: true,
          onDismiss: () => setShowPreferencesNotSaved(false),
          content: (
            <>
              {bundle.getMessage('preferences-not-saved')}
            </>
          ),
          type: 'error',
        }]}
      />}
      <Table
        {...collectionProps}
        trackBy='DeviceName'
        ariaLabels={{
          selectionGroupLabel: bundle.getMessage('devices-selection'),
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item) => {
            const isItemSelected = selectedItems.filter(
              i => i.DeviceName === item.DeviceName
            ).length;
            return `${item.DeviceName} is ${isItemSelected ? '' : 'not'
              } selected`;
          }
        }}
        columnDefinitions={
          [
            ...ColumnDefinitions.slice(0, 5),
            {
              id: 'SetInputMask',
              header: bundle.getMessage('set-mask'),
              cell: (item: SilencioDevice) =>
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => setInputMaskButtonHandler(item, 'Masked')}
                    disabled={(item.Masked === true) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('mask')}
                  </Button>
                  <Button
                    onClick={() => setInputMaskButtonHandler(item, 'Unmask')}
                    disabled={(item.Masked === false) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('unmask')}
                  </Button>
                </SpaceBetween>
            },
            ...ColumnDefinitions.slice(5, 6),
            {
              id: 'SetReaderDoorForcedMask',
              header: bundle.getMessage('set-door-forced-open-mask'),
              cell: (item: SilencioDevice) =>
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => setReaderDoorForcedMaskButtonHandler(item, 'Masked')}
                    disabled={(item.DoorForcedMasked === true) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('mask')}
                  </Button>
                  <Button
                    onClick={() => setReaderDoorForcedMaskButtonHandler(item, 'Unmask')}
                    disabled={(item.DoorForcedMasked === false) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('unmask')}
                  </Button>
                </SpaceBetween>
            },
            ...ColumnDefinitions.slice(6, 7),
            {
              id: 'SetReaderDoorHeldMask',
              header: bundle.getMessage('set-door-held-open-mask'),
              cell: (item: SilencioDevice) =>
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => setReaderDoorHeldMaskButtonHandler(item, 'Masked')}
                    disabled={(item.DoorHeldMasked === true) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('mask')}
                  </Button>
                  <Button
                    onClick={() => setReaderDoorHeldMaskButtonHandler(item, 'Unmask')}
                    disabled={(item.DoorHeldMasked === false) || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('unmask')}
                  </Button>
                </SpaceBetween>
            },
            ...ColumnDefinitions.slice(7, 8),
            {
              id: 'SetMode',
              header: bundle.getMessage('set-mode'),
              cell: (item: SilencioDevice) =>
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => setReaderModeButtonHandler(item, 'Card and Pin')}
                    disabled={(item.Mode === 'Card and Pin') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('card-and-pin')}
                  </Button>
                  <Button
                    onClick={() => setReaderModeButtonHandler(item, 'Card Only')}
                    disabled={(item.Mode === 'Card Only') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('card-only')}
                  </Button>
                  {(phySecMember || sideTeam || sigAdmin)
                    && <Button
                      onClick={() => setReaderModeButtonHandler(item, 'Facility Code')}
                      disabled={(item.Mode === 'Facility Code' || item.Mode === 'Facility Code Only') || (item.read_only ?? false)}
                      disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                    >
                      {commonBundle.getMessage('facility-code')}
                    </Button>
                  }
                  <Button
                    onClick={() => setReaderModeButtonHandler(item, 'Locked')}
                    disabled={(item.Mode === 'Locked' || item.Mode === 'Locked Down') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('locked')}
                  </Button>
                  <Button
                    onClick={() => setReaderModeButtonHandler(item, 'Pin or Card')}
                    disabled={(item.Mode === 'Pin or Card') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('pin-or-card')}
                  </Button>
                  <Button
                    onClick={() => setReaderModeButtonHandler(item, 'Unlocked')}
                    disabled={(item.Mode === 'Unlocked') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('unlocked')}
                  </Button>
                </SpaceBetween>
            },
            ...ColumnDefinitions.slice(8),
            {
              id: 'OutputActions',
              header: bundle.getMessage('actions'),
              cell: (item: SilencioDevice) =>
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    onClick={() => setOutputCommandHandler(item, "Activate")}
                    disabled={(item.OutputStatus === 'Activated') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('activate')}
                  </Button>
                  <Button
                    onClick={() => setOutputCommandHandler(item, "Deactivate")}
                    disabled={(item.OutputStatus === 'Deactivated') || (item.read_only ?? false)}
                    disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                  >
                    {commonBundle.getMessage('deactivate')}
                  </Button>
                  <div
                    title={(item.OutputStatus === 'Activated') ? bundle.getMessage('cannot-pulse') : ''}
                  >
                    <Button
                      onClick={() => setOutputCommandHandler(item, "Pulse")}
                      disabled={(item.OutputStatus === 'Activated') || (item.read_only ?? false)}
                      disabledReason={item.read_only ? bundle.getMessage('restricted-device') : ''}
                    >
                      {commonBundle.getMessage('pulse')}
                    </Button>
                  </div>
                </SpaceBetween>
            },
          ]
        }
        columnDisplay={
          [
            { id: 'DeviceName', visible: true },
            { id: 'DeviceStatus', visible: true },
            { id: 'DeviceType', visible: true },
            { id: 'PairedReader', visible: (props.deviceType === 'readersAlarms') },
            { id: 'DoorForcedOpen', visible: (props.deviceType === 'readersAlarms') },
            { id: 'SetReaderDoorForcedMask', visible: 
              (props.deviceType === 'readersAlarms')
              && (userSitePrivs !== undefined
                && userSitePrivs.length > 0
                && userSitePrivs.includes(Privileges.all)
                && !onguardDevices())
            },
            { id: 'DoorHeldOpen', visible: (props.deviceType === 'readersAlarms') },
            { id: 'SetReaderDoorHeldMask', visible: 
              (props.deviceType === 'readersAlarms')
              && (userSitePrivs !== undefined
                && userSitePrivs.length > 0
                && userSitePrivs.includes(Privileges.all)
                && !onguardDevices())
            },
            { id: 'IORelationOutputNames', visible: (props.deviceType === 'inputs') },
            { id: 'Masked', visible: (props.deviceType === 'inputs') },
            { id: 'Mode', visible: (props.deviceType === 'readersModes') },
            { id: 'OutputStatus', visible: (props.deviceType === 'outputs') },
            { id: 'OutputActions', visible: (props.deviceType === 'outputs') },
            { id: 'SetInputMask', visible: (props.deviceType === 'inputs') },
            { id: 'SetMode', visible: (props.deviceType === 'readersModes') },
            { id: 'ReadOnly', visible: true },
          ]
        }
        empty={<TableEmptyState title={bundle.formatMessage('number-of-devices', { deviceCount: 0 })} />}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        selectedItems={selectedDevicesFromStore}
        items={items}
        isItemDisabled={item => item.read_only ?? false}
        loadingText={bundle.getMessage('loading')}
        loading={props.loadingDevices}
        selectionType={props.deviceType === 'outputs' ? 'single' : 'multi'}
        filter={
          <div className='input-container'>
            {getFilter()}
          </div>
        }
        header={
          <>
            <Header
              counter={
                selectedDevicesFromStore?.length
                  ? '(' + selectedDevicesFromStore.length + `/${devicesFromStore?.length})`
                  : `(${devicesFromStore?.length || 0})`
              }
            >
              {bundle.formatMessage('table-header', { deviceType: props.deviceType })}
            </Header>
            <ButtonBar
              deviceType={props.deviceType}
              onguardDevices={onguardDevices()}
              selectedItemsLength={selectedDevicesFromStore?.length}
              selectDeselectAllButton={selectDeselectAllButton}
              selectDeselectAllCallBack={selectDeselectAll}
              selectDeselectAllDisabled={selectDeselectAllDisabled}
              setChangeModeVisibleCallback={setChangeModeVisible}
              setChangeStatusVisibleCallback={setChangeStatusVisible}
              refreshDevicesCallback={props.refreshDevicesCallback}
              userSitePrivs={userSitePrivs}
            />
          </>
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            title={prefsBundle.getMessage('user-preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              custom: { emailAddresses: emailAddresses },
              pageSize: pageSize,
              visibleContent: [
                'DeviceName'
              ]
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25 }) },
                { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50 }) },
                { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100 }) },
                { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150 }) },
                { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250 }) },
                { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500 }) }
              ],
            }}
            customPreference={(customValue, setCustomValue) => (
              <FormField
                description={prefsBundle.getMessage('email-addresses-description')}
                label={prefsBundle.getMessage('email-addresses')}
              >
                <Textarea
                  invalid={customValue.emailAddresses !== '' && !validEmailAddresses(customValue.emailAddresses)}
                  onChange={({ detail }) => setCustomValue({ emailAddresses: detail.value })}
                  placeholder={prefsBundle.getMessage('email-addresses-description')}
                  value={customValue.emailAddresses}
                />
              </FormField>
            )}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
      />
      {changeStatusVisible
        && <ChangeStatus
          changeStatusVisible={changeStatusVisible}
          deviceType={props.deviceType}
          emailAddresses={emailAddresses}
          setChangeStatusVisibleCallback={setChangeStatusVisible}
          selectedDevices={selectedDevicesFromStore}
          singleSelection={singleSelection}
          totalDevicesCount={devicesFromStore?.length}
          updateDeviceCallback={updateDevice}
        />
      }
      {changeModeVisible
        && <ChangeMode
          changeModeVisible={changeModeVisible}
          emailAddresses={emailAddresses}
          setChangeModeVisibleCallback={setChangeModeVisible}
          selectedDevices={selectedDevicesFromStore}
          singleSelection={singleSelection}
          totalDevicesCount={devicesFromStore?.length}
          updateDeviceCallback={updateDevice}
        />
      }
      {changeOutputVisible
        && <ChangeOutput
          changeOutputVisible={changeOutputVisible}
          emailAddresses={emailAddresses}
          setChangeOutputVisibleCallback={setChangeOutputVisible}
          selectedDevices={selectedDevicesFromStore}
          singleSelection={singleSelection}
          totalDevicesCount={devicesFromStore?.length}
          updateDeviceCallback={updateDevice}
        />
      }
    </div>
  );

}

export default DevicesTable;