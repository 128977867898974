import * as APIt from "../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { Paths, TemplateTypes, UserActionNames } from 'src/constants/Constants';
import { SilencioDevice, TemplateRow } from 'src/utils/SilencioTypes';
import { debug, getPACSDataAPIStage } from './commonUtils';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { getSiteTemplateDevices as getSiteTemplateDevicesQuery } from '../graphql/queries'
import { upsertSiteTemplate as upsertSiteTemplateMutation } from '../graphql/mutations'

export const getSiteTemplateDevices = async (siteCode: string, siteRegion: string, templateType: string, requestedBy: string): Promise<APIt.TemplateDevice[]> => {
  debug(`getSiteTemplateDevices() siteCode: ${siteCode} siteRegion: ${siteRegion} templateType: ${templateType}`);
  let stage = 'gamma';
  debug(`getSiteTemplateDevices() stage is: ${stage}`);
  try {
    createUserAction({
      actionName: UserActionNames.GetSiteTemplateDevices,
      username: requestedBy,
      parameters: JSON.stringify({ siteCode, templateType })
    });
    const response = await API.graphql(graphqlOperation(getSiteTemplateDevicesQuery, {
      path: `${Paths.SiteTemplateDevices}/region/${siteRegion}/stage/${getPACSDataAPIStage(stage)}/?SiteCode=${siteCode}&TemplateType=${templateType}`
    })) as GraphQLResult<APIt.GetSiteTemplateDevicesQuery>;

    debug(`getSiteTemplateDevices() response is ${JSON.stringify(response)}`);
    createUserAction({
      actionName: UserActionNames.GetSiteTemplateDevicesResult,
      username: requestedBy,
      parameters: JSON.stringify({ response, siteCode, templateType })
    });
    return response.data?.getSiteTemplateDevices as APIt.TemplateDevice[];
  }
  catch (error) {
    debug(`getSiteTemplateDevices() error is ${error}`);
    createUserAction({
      actionName: UserActionNames.GetSiteTemplateDevicesError,
      username: requestedBy,
      parameters: JSON.stringify({ error, siteCode, templateType })
    });
    throw error;
  }
}

export const upsertSiteTemplate = async (
  addDevices: string | null,
  addMaskingGroups: string | null,
  desiredState: string,
  removeDevices: string | null,
  removeMaskingGroups: string | null,
  requestedBy: string,
  siteRegion: string,
  templateType: TemplateTypes
): Promise<APIt.SiteTemplateResponse> => {
  const input = { addDevices, addMaskingGroups, desiredState, removeDevices, removeMaskingGroups, requestedBy, templateType };
  debug(`upsertSiteTemplate() input is ${JSON.stringify(input)}`);
  let stage = 'gamma';
  debug(`upsertSiteTemplate() stage is: ${stage}`);
  try {
    if (!addDevices && !addMaskingGroups && !removeDevices && !removeMaskingGroups) {
      throw new Error(`No devices or masking groups to add or remove from site ${templateType} template`);
    }
    const parameters: string[] = [
      `Created_By=${requestedBy}`,
      `Desired_State=${desiredState}`,
      `TemplateType=${templateType}`,
      'outputFormat=json'
    ];
    if (addDevices) {
      parameters.push(`AddList_devices=${addDevices}`);
    }
    if (addMaskingGroups) {
      parameters.push(`AddList_groups=${addMaskingGroups}`);
    }
    if (removeDevices) {
      parameters.push(`DeleteList_devices=${removeDevices}`);
    }
    if (removeMaskingGroups) {
      parameters.push(`DeleteList_groups=${removeMaskingGroups}`);
    }

    createUserAction({
      actionName: UserActionNames.UpsertSiteTemplate,
      username: requestedBy,
      parameters: JSON.stringify({ input })
    });

    const response = await API.graphql(graphqlOperation(upsertSiteTemplateMutation,
      {
        path: `${Paths.UpsertSiteTemplate}/region/${siteRegion}/stage/${getPACSDataAPIStage(stage)}/?${parameters.join('&')}`
      })) as GraphQLResult<APIt.UpsertSiteTemplateMutation>;

    debug(`upsertSiteTemplate() response is ${JSON.stringify(response)}`);
    createUserAction({
      actionName: UserActionNames.UpsertSiteTemplateResult,
      username: requestedBy,
      parameters: JSON.stringify({ input, response })
    });

    if (response.data && response.data.upsertSiteTemplate && response.data.upsertSiteTemplate.length == 1) {
      return (response.data.upsertSiteTemplate as APIt.SiteTemplateResponse[])[0];
    } else {
      throw new Error(response.errors?.toString() ?? 'Error parsing upsertSiteTemplateMutation response.');
    }
  } catch (error) {
    console.error(`upsertSiteTemplate() error is ${error}`);
    createUserAction({
      actionName: UserActionNames.UpsertSiteTemplateError,
      username: requestedBy,
      parameters: JSON.stringify({ error, input })
    });
    throw error;
  }
}

/**
 * This will return a list of site template table rows given a list of devices found in a template(s). 
 * The template devices are all of the devices related to a template(s) and may have metadata to show if they are in a template due to being in a masking group. 
 * The site template table shows rows as a device or as a masking group, so the TemplateDevice[] items with a masking group need to be reduced to one masking group row.
 * When the action on a device is a reader alarm action the readerAlarmsDevices list will be used to add a paired reader to the template row.
 * 
 * @param readersAlarmsDevices list of reader alarm devices
 * @param templateDevices input list of template devices
 * @returns A list of TemplateRows
 */
export const extractTemplateRows = (readersAlarmsDevices: SilencioDevice[], templateDevices: APIt.TemplateDevice[]): TemplateRow[] => {
  debug(`extractTemplateRows() input is ${JSON.stringify(templateDevices)}`);

  const templateRows: TemplateRow[] = [];
  templateDevices.forEach(templateDevice => {
    if (templateDevice.EntityType === 'Masking_Group') {
      const existingRow = templateRows.find(templateRow => (
        templateRow.templateType === templateDevice.TemplateType
        && templateRow.action === templateDevice.Desired_State
        && templateRow.entityId === templateDevice.Entity
      ));
      if (!existingRow) {
        templateRows.push({
          templateType: templateDevice.TemplateType,
          action: templateDevice.Desired_State,
          createdBy: templateDevice.Template_Created_By,
          createdDate: templateDevice.Template_Created_Date_UTC,
          entityId: templateDevice.Entity,
          entityName: templateDevice.MaskGroupName ?? 'Unknown Masking Group',
          entityType: templateDevice.MaskGroupType ?? 'Unknown Masking Group Type'
        });
      }
    } else if (templateDevice.EntityType === 'Device') {
      const templateRow: TemplateRow = {
        templateType: templateDevice.TemplateType,
        action: templateDevice.Desired_State,
        createdBy: templateDevice.Template_Created_By,
        createdDate: templateDevice.Template_Created_Date_UTC,
        entityId: templateDevice.Entity,
        entityName: templateDevice.DeviceName,
        entityType: templateDevice.Device_Type,
      };

      const readerAlarmActions = ['Mask Door Forced Open', 'Unmask Door Forced Open', 'Mask Door Held Open', 'Unmask Door Held Open'];
      if (readerAlarmActions.includes(templateDevice.Desired_State)) {
        const device = readersAlarmsDevices.find(readersAlarmsDevice =>
          `${readersAlarmsDevice.Parent_DeviceID}_${readersAlarmsDevice.Child_DeviceID}_${readersAlarmsDevice.Subchild_DeviceID}` === templateDevice.amzn_key
        );
        if (device) {
          templateRow.pairedReader = device.PairedReader
        }
      }
      
      templateRows.push(templateRow);
    } else {
      console.error(`extractTemplateRows() error: unhandled template device: ${JSON.stringify(templateDevices)}`);
    }
  });

  return templateRows;
}